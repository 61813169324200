<form class="statement-form">
  <div style="display: flex">
    <div>
      <div class="input-button-row">
        <button
          mat-button
          *ngIf="sentenceToGenerate.generator.length > 0"
          (click)="generate()"
          color="primary"
          [disabled]="questionAlreadyExists || checkingExistingQuestion"
          class="add-button generate-button">
          {{updateStatement ? 'Update' : 'Save'}}
        </button>

        <button
          mat-button
          *ngIf="sentenceToGenerate.generator.length > 0 && updateStatement"
          (click)="generateNew()" color="primary"
          class="add-button generate-button">
          Save new
        </button>

        <button
          mat-button
          *ngIf="sentenceToGenerate.generator.length > 0"
          (click)="resetForm()" color="primary"
          class="cancel-button">
          Cancel
        </button>

        <mat-hint
          align="start"
          *ngIf="!validGenerator"
          class="error-hint">
          No correct forms. {{generatorError}}
        </mat-hint>
      </div>

      <mat-divider class="divider"></mat-divider>

      <div class="nest-container">
        <button
          mat-button
          (click)="addQuestion()"
          color="primary"
          class="add-button">
          Add Question
        </button>
      </div>

      <mat-divider class="divider"></mat-divider>

      <div class="nest-container">
        <button
          mat-button
          (click)="addSimpleText()"
          color="primary"
          class="add-button">
          Add Simple Text
        </button>

        <button
          mat-button
          (click)="addNewPhrase()"
          color="primary"
          class="add-button">
          Add Phrase
        </button>

        <button
          mat-button
          (click)="addPunctuation()"
          color="primary"
          class="add-button">
          Add Punctuation
        </button>

        <div class="add-phrase-button-container" *ngIf="sentenceToGenerate.generator.length">
          <div class="add-phrase-button-wrapper">
            <button mat-raised-button class="add-phrase-button" (click)="setActiveDirection(false)"
                    matTooltip="Add above"
                    [ngClass]="{ 'add-phrase-button-active': !addBelow}">
              <mat-icon>expand_less</mat-icon>
            </button>
          </div>
          <div class="add-phrase-button-wrapper">
            <button mat-raised-button class="add-phrase-button" style="margin-top: 1px;"
                    matTooltip="Add below"
                    (click)="setActiveDirection(true)" [ngClass]="{ 'add-phrase-button-active': addBelow}">
              <mat-icon>expand_more</mat-icon>
            </button>
          </div>
        </div>
        <label *ngIf="sentenceToGenerate.generator.length" class="nest-label">Variants: </label>

        <section *ngIf="sentenceToGenerate.generator.length" class="nest-section">
          <mat-slide-toggle
            [ngModelOptions]="{standalone: true}"
            class="example-margin"
            [color]="'primary'"
            [(ngModel)]="nestPart">
          </mat-slide-toggle>
        </section>
      </div>
    </div>
  </div>

  <mat-divider
    *ngIf="sentenceToGenerate.generator.length"
    class="divider">
  </mat-divider>

  <div style="display: flex;">
    <div class="sentence-part">
      <div class="input-row" *ngIf="this.question">
        <label class="generator-label">Question: </label>

        <mat-form-field class="q-input">
          <input matInput value="" (keyup)="onQuestionInputKeyUp()" [(ngModel)]="this.question.question"
                 [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <div class="button-container">
          <button mat-button (click)="removeQuestion()" class="button" matTooltip="Remove">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="questionAlreadyExists">
        <label class="error-hint">Tato otázka již existuje</label>
      </div>

      <ul *ngIf="sentenceToGenerate.generator.length > 0" class="bar">
        <ng-container *ngFor="let part of sentenceToGenerate.generator; let i = index;">
          <ul *ngIf="part.length > 1; else noArray" class="nested-bar">
            <li
              *ngFor="let subPart of part; let j = index"
              class="li-container-nested">
              <button
                type="button"
                mat-button (click)="changeActiveRows(i, j)"
                class="edit-button-nested"
                [ngClass]="{ 'edit-button-nested-active': rows[i] && rows[i][j] }"
                matTooltip="Set active row">
              </button>

              <mat-form-field *ngIf="subPart.generator[0] !== undefined; else statement" class="li-input">
                <input
                  matInput
                  value=""
                  placeholder="Simple text"
                  [(ngModel)]="subPart.generator[0]"
                  [ngModelOptions]="{standalone: true}">
              </mat-form-field>

              <ng-template #statement>
                <app-statement-edit
                  [phrasesArray]="phrasesArray"
                  [wordDb]="wordDb"
                  [statement]="subPart.generator">
                </app-statement-edit>
              </ng-template>

              <div class="button-container">
                <button mat-button (click)="formsPreviewEnhance(subPart.generator, i)" class="button"
                        matTooltip="Phrase preview">
                  <mat-icon>search</mat-icon>
                </button>

                <button mat-button (click)="removeItem(i, j)" class="button" matTooltip="Remove">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </div>
            </li>
          </ul>

          <ng-template #noArray>
            <li class="li-container">
              <button type="button" mat-button (click)="changeActiveRows(i,0)" class="edit-button"
                      [ngClass]="{ 'edit-button-nested-active': rows[i] && rows[i][0]}" matTooltip="Set active row">
              </button>

              <mat-form-field *ngIf="part[0].generator[0] !== undefined; else phrase" class="li-input">
                <input matInput value="" placeholder="Simple text" [(ngModel)]="part[0].generator[0]"
                       [ngModelOptions]="{standalone: true}">
              </mat-form-field>

              <ng-template #phrase>
                <app-statement-edit
                  [phrasesArray]="phrasesArray"
                  [wordDb]="wordDb"
                  [statement]="part[0].generator">
                </app-statement-edit>
              </ng-template>

              <div class="button-container">
                <button mat-button (click)="formsPreviewEnhance(part[0].generator, i)" class="button"
                        matTooltip="Phrase preview">
                  <mat-icon>search</mat-icon>
                </button>
                <button mat-button (click)="removeItem(i)" class="button" matTooltip="Remove">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </div>
            </li>
          </ng-template>
        </ng-container>
      </ul>

      <div class="input-row" *ngIf="this.punctuation">
        <label class="generator-label">Punctuation: </label>

        <mat-form-field class="input-form-30">
          <mat-select [(ngModel)]="this.punctuation" [ngModelOptions]="{standalone: true}">
            <mat-option *ngFor="let punctuation of punctuations" [value]="punctuation">
              {{ punctuation }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="button-container">
          <button mat-button (click)="removePunctuation()" class="button" matTooltip="Remove">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="form-preview">
      <div *ngIf="morphedForms" class="form-preview-container">
        <label class="form-preview-title">Forms preview</label>
      </div>

      <ng-container *ngFor="let sentence of morphedForms">
        <div class="correct-incorrect-border">Correct:</div>
        <div *ngFor="let answer of sentence.correct">
          <div class="values-margin">{{ answer }}</div>
        </div>

        <div class="correct-incorrect-border">Incorrect:</div>
        <div *ngFor="let answer of sentence.incorrect">
          <div class="values-margin">{{ answer.sentence ? answer.sentence : answer }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</form>
