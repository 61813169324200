<mat-toolbar class="mb-1">
  <h2>
    <a [routerLink]="['/']">
      <img src="assets/icon.png" width="35">
      Čeština levou zadní
    </a>
  </h2>

  <div
    class="buttons"
    *ngIf="userProfile$ | async">
    <a
      mat-raised-button
      target="_blank"
      href="https://ceskylevouzadni.cz/app"
      color="primary">
      <mat-icon>open_in_new</mat-icon>
      Go to app
    </a>

    <button
      mat-stroked-button
      type="button"
      (click)="logout()"
      color="error">
      <mat-icon>exit_to_app</mat-icon>
      Logout
    </button>
  </div>
</mat-toolbar>

<mat-drawer-container
  *ngIf="(userProfile$ | async); else loginFormRef"
  class="wrapper">
  <mat-drawer mode="side" opened>
    <app-topic-list></app-topic-list>
  </mat-drawer>

  <mat-drawer-content class="content">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #loginFormRef>
  <mat-card>
    <form
      [formGroup]="form"
      (submit)="login()">

      <h2 class="u-text-center mb-1">Login to Čeština&nbsp;levou&nbsp;zadní</h2>

      <mat-form-field>
        <input
          matInput
          placeholder="Username"
          type="text"
          formControlName="email">
        <mat-hint align="end">required field</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="Password"
          type="password"
          formControlName="password">
        <mat-hint align="end">required field</mat-hint>
      </mat-form-field>

      <div class="u-text-center mt-1 mb-1">
        <button
          class="w-100"
          mat-flat-button
          type="submit"
          [disabled]="form.invalid"
          color="primary">
          Login
        </button>
      </div>
    </form>
  </mat-card>
</ng-template>
